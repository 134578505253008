import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 45,
    title: "Dockerizzare una App React:",
    desc: "Una Guida Pratica",
    img: "/blog-image/docker.jpg",
    page: "blog/docker",
    data: "24 Nov 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Le richieste dei clienti cambiano rapidamente e le esigenze di scalabilità sono in costante evoluzione, è quindi fondamentale avere strumenti che semplifichino il processo di distribuzione delle applicazioni.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Le richieste dei clienti cambiano
                                        rapidamente e le esigenze di scalabilità
                                        sono in costante evoluzione, è quindi
                                        fondamentale avere strumenti che
                                        semplifichino il processo di
                                        distribuzione delle applicazioni. Ecco
                                        perché sempre più aziende stanno
                                        abbracciando Docker e il concetto di
                                        containerizzazione per gestire le
                                        proprie applicazioni in modo più
                                        efficiente ed efficace. Ma cosa
                                        significa "dockerizzare" una app React e
                                        quali benefici comporta? In questo
                                        articolo, esploreremo il mondo di Docker
                                        e scopriremo come questa tecnologia può
                                        rivoluzionare il tuo processo di
                                        sviluppo e distribuzione.
                                    </p>
                                    <h3>Docker: Un Breve Introduzione</h3>
                                    <p>
                                        Docker è una piattaforma open-source che
                                        consente di creare, distribuire e
                                        gestire container leggeri. Un container
                                        è un ambiente isolato che contiene
                                        un'applicazione e tutte le sue
                                        dipendenze, garantendo che
                                        l'applicazione funzioni in modo coerente
                                        ovunque venga eseguita,
                                        indipendentemente dall'ambiente
                                        sottostante. Questo è un passo avanti
                                        rispetto alle tradizionali macchine
                                        virtuali, che richiedono più risorse e
                                        sono meno efficienti.
                                    </p>
                                    <h3>Perché Dockerizzare una App React?</h3>
                                    <ol>
                                        <li>
                                            <p>
                                                <b>Portabilità</b>: Una volta
                                                che hai dockerizzato la tua app
                                                React, puoi essere sicuro che
                                                funzionerà su qualsiasi ambiente
                                                Docker compatibile, che sia il
                                                tuo server locale, il cloud o un
                                                server di produzione. Non dovrai
                                                più preoccuparti di
                                                configurazioni complesse o di
                                                problemi di compatibilità.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Isolamento</b>: I container
                                                Docker sono isolati gli uni
                                                dagli altri e dall'host, il che
                                                significa che puoi eseguire più
                                                container su un singolo server
                                                senza temere interferenze o
                                                collisioni. Questo è essenziale
                                                per l'isolamento delle
                                                applicazioni e la sicurezza dei
                                                dati.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Scalabilità</b>: Docker
                                                semplifica la scalabilità delle
                                                tue applicazioni. Puoi
                                                facilmente aumentare o diminuire
                                                il numero di container in base
                                                alle esigenze del tuo carico di
                                                lavoro, garantendo prestazioni
                                                ottimali in ogni momento.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Gestione Semplificata</b>:
                                                Docker offre un'ampia gamma di
                                                strumenti per la gestione dei
                                                container, tra cui Docker
                                                Compose per definire
                                                l'architettura delle
                                                applicazioni e Docker Swarm per
                                                l'orchestrazione dei container
                                                su più host. Questi strumenti
                                                semplificano notevolmente la
                                                gestione delle applicazioni
                                                complesse.
                                            </p>
                                        </li>
                                    </ol>
                                    <h3>
                                        Come Dockerizzare una App React: Esempi
                                        Pratici
                                    </h3>
                                    <p>
                                        Per dockerizzare una app React, è
                                        necessario creare un Dockerfile che
                                        definisca il processo di costruzione del
                                        container. Ecco un esempio di Dockerfile
                                        per un'app React:
                                    </p>
                                    <ul>
                                        <li>
                                            <MyCoolCodeBlock
                                                code={`// Usa un'immagine di base con Node.js
        FROM node:14`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li className="pt-3">
                                            <MyCoolCodeBlock
                                                code={`// Imposta la directory di lavoro nel container
WORKDIR /app`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li className="pt-3">
                                            <MyCoolCodeBlock
                                                code={`// Copia i file di dipendenza del package.json e del package-lock.json
COPY package*.json ./`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <br />
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <li className="pt-3">
                                            <MyCoolCodeBlock
                                                code={`// Esegui l'installazione delle dipendenze
RUN npm install`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li className="pt-3">
                                            <MyCoolCodeBlock
                                                code={`// Copia il codice sorgente dell'app nella directory di lavoro
COPY . .`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li className="pt-3">
                                            <MyCoolCodeBlock
                                                code={`// Esponi la porta su cui verrà eseguita l'app
EXPOSE 3000`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li className="pt-3">
                                            <MyCoolCodeBlock
                                                code={`// Comando per avviare l'app
CMD ["npm", "start"]`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                    </ul>
                                    <p>
                                        In questo esempio, stiamo usando
                                        un'immagine di base Node.js, installando
                                        le dipendenze, copiando il codice
                                        sorgente dell'app e definendo il comando
                                        per avviare l'applicazione.
                                    </p>
                                    <p>
                                        Una volta creato il Dockerfile, puoi
                                        costruire l'immagine del container con
                                        il seguente comando:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`docker build -t nome-app . `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Dopo aver creato l'immagine, puoi
                                        eseguire il container con il comando:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`docker run -p 3000:3000 nome-app`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        La tua app React sarà ora in esecuzione
                                        all'interno del container Docker.
                                    </p>
                                    <p>
                                        Come abbiamo visto, Docker offre una
                                        soluzione potente e flessibile per la
                                        distribuzione delle tue app. Abbiamo
                                        visto React, essendo il nostro ambiente
                                        preferito, ma avremmo potuto usare
                                        qualsiasi cosa. La sua portabilità,
                                        l'isolamento, la scalabilità e la
                                        gestione semplificata lo rendono uno
                                        strumento essenziale per qualsiasi
                                        azienda che voglia migliorare
                                        l'efficienza del proprio sviluppo e la
                                        distribuzione delle applicazioni.
                                        Dockerizzare la tua app potrebbe essere
                                        la soluzione per la scalabilità che
                                        cerchi, e noi siamo qui per aiutarti a
                                        sfruttare al meglio questa tecnologia.
                                        Contattaci oggi stesso per scoprire come
                                        possiamo aiutarti a ottimizzare il tuo
                                        processo di sviluppo e distribuzione.
                                        Nel frattempo, come sempre, buon coding!
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
